import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import ProjectHighlight from '../components/projectHighlight'
import Layout from '../components/layout'

export default ({ data }) => (
  <Layout>
    <Container fluid className="portfolio">
      <Row>
        <Col className="top-col page">
          <h1>Portfolio</h1>
        </Col>
      </Row>
      <div className="project-grid">
        {data.projects.edges.map(({ node }, index) => {
          const image = data.projectImages.edges.find(edge => {
            return (
              edge.node.relativePath ===
              `projects/${node.frontmatter.graphqlKey}/${
                node.frontmatter.graphqlKey
              }1.jpg`
            )
          })
          const fluid = image.node.childImageSharp.fluid
          return <ProjectHighlight key={node.id} node={node} fluid={fluid} />
        })}
      </div>
    </Container>
  </Layout>
)

export const query = graphql`
  query {
    projects: allMarkdownRemark(
      filter: { fields: { slug: { regex: "/projects/.*/" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            longTitle
            graphqlKey
            url
            urlShort
            url2
          }
          fields {
            slug
          }
        }
      }
    }
    projectImages: allFile(
      sort: { order: ASC, fields: [absolutePath] }
      filter: { relativePath: { regex: "/projects/.*1.jpg/" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
